<template>
  <iframe class="i-frame full-flex" ref="i-frame"></iframe>
</template>

<script>
export default {
  name: 'IFrame',
  data: () => ({
    iframeBody: null
  }),
  props: {
    content: Object
  },
  watch: {
    content(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
      }
    }
  },
  methods: {
    destroy() {
      if (this.iframeBody) {
        this.iframeBody.innerHTML = ''
      }
    },

    init() {
      this.destroy()

      if (!this.content || !this.iframeBody) {
        return
      }

      this.iframeBody.appendChild(this.content)
    }
  },
  mounted() {
    this.iframeBody = this.$refs['i-frame'].contentDocument.body

    this.ready = true

    this.init()
  },
  beforeDestroy() {
    this.destroy()
  }
}
</script>

<style lang="scss" scoped>
  .i-frame {
    border: 1px solid #eee;
  }

  .full-flex {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
</style>
